<template>
  <div class="private">
    <!-- <promo-banner></promo-banner> -->
    <div class="private-info">
      <div class="private-info__content">
        <page-title class="private-info__title">
          {{ $t('title') }}
        </page-title>
        <div v-if="list.length" class="private-mode">
          <plain-button
            icon="grid"
            class="private-mode__btn"
            watch-active
            :active="mode === 'grid'"
            @click="setMode('grid')"
          />
          <plain-button
            icon="list"
            class="private-mode__btn"
            watch-active
            :active="mode === 'table'"
            @click="setMode('table')"
          />
        </div>
      </div>
      <div v-if="list.length" class="private-info__create">
        <base-button :to="{ name: 'privateOrder' }">
          {{ $t('newServer') }}
        </base-button>
      </div>
    </div>
    <transition name="fade" mode="out-in" appear>
      <div v-if="isLoading" class="private-info__loader">
        <base-loader class="private-info__loader-item" />
      </div>
      <base-alert
        v-else-if="!isLoading && isServerError"
        :title="$t('errors.serverError.title')"
        icon="warn"
        class="private-info__alert"
      >
        {{ $t('errors.serverError.text') }}
      </base-alert>
      <base-empty
        v-else-if="!isLoading && !list.length && !isServerError"
        :title="$t('empty.title')"
        :text="$t('empty.text')"
        :link="{ title: $t('createServer'), to: { name: 'privateOrder' } }"
        class="private-info__empty"
      />
      <div v-else class="private-content">
        <transition name="fade" mode="out-in">
          <component :is="modeComponents[mode]" :dataset="list" />
        </transition>
      </div>
    </transition>
  </div>
</template>

<script>
import MainButton from '@/components/Buttons/MainButton.vue';
import TariffsGrid from './components/TariffsGrid';
import TariffsTable from './components/TariffsTable';
import BaseEmpty from '@/components/BaseEmpty/BaseEmpty';
import BaseAlert from '@/components/BaseAlert/BaseAlert';
import storeMixin from '../../mixins/index';
export default {
  name: 'PrivateMain',
  components: {
    MainButton,
    TariffsGrid,
    TariffsTable,
    BaseEmpty,
    BaseAlert,
  },
  mixins: [storeMixin],
  data() {
    return {
      modeComponents: {
        grid: TariffsGrid,
        table: TariffsTable,
      },
      isServerError: false,
    };
  },
  computed: {
    mode() {
      return this.$store.state.modulePrivate.mode;
    },
  },
  mounted() {
    if (!this.list.length) {
      this.fetchList().catch(e => {
        if (e.status && e.status === 520) this.isServerError = true;
      });
    }
  },
  methods: {
    setMode(val) {
      this.$store.commit('modulePrivate/SET_MODE', val);
    },
  },
};
</script>

<i18n>
  {
    "ru": {
      "title": "Частное облако",
      "newServer": "Создать частное облако",
      "createServer": "Частное облако",
      "empty": {
        "title": "На данный момент у вас нет ни одного виртуального хостинга",
        "text": "Виртуальный хостинг — разумный и недорогой выбор для размещения сайтов-визиток компаний, не ведущих продажи через интернет, а также для блогов и некоммерческих проектов"
      }
    }
  }
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';

.private {
  display: flex;
  flex-direction: column;
  min-size(100%);
  flex: 1 1 100%;
  &-info {
    +breakpoint(sm-and-up) {
      flexy(space-betweeb, center);
    }

    &__content {
      flexy(space-between, center);

      +breakpoint(sm-and-up) {
        justify-content: flex-start;
        margin-right: 1.5rem;
        flex: 1 1 auto;
      }
    }

    &__loader {
      flexy(center, center);
      flex: 1 1 auto;
    }

    &__create {
      flex: 0 0 auto;
      margin-top: 1.5rem;
      max-width: 160px;

      +breakpoint(sm-and-up) {
        margin: 0 0 0 auto;
      }
    }
  }

  &-mode {
    flexy(flex-end, center);
    margin-left: auto;

    +breakpoint(sm-and-up) {
      justify-content: flex-start;
      margin-left: 1.5rem;
    }

    &__btn {
      & + & {
        margin-left: 1rem;
      }
    }
  }

  &-content {
    margin-top: 1.5rem;

    +breakpoint(sm-and-up) {
      margin-top: 2rem;
    }
    +breakpoint(md-and-up) {
      margin-top: 2.5rem;
    }
  }
}
</style>

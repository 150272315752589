<template>
  <div class="private-context-menu">
    <template v-if="menu.length && menu[0].length">
      <div v-for="(group, i) in menu" :key="i" class="private-context-menu__group">
        <component
          :is="item.attrs ? 'a' : 'div'"
          v-for="item in group"
          :key="item.key"
          v-bind="item.attrs"
          class="private-context-menu__item standart-title"
          :class="{ [`private-context-menu__item--${item.color}`]: !!item.color }"
          :disabled="item.disabled"
          @click="onClick(item)"
        >
          {{ $t(`actions.${item.key}`) }}
        </component>
      </div>
    </template>
    <template v-else>
      <div class="clouds-context-menu__noact standart-text">
        <div>{{ $t('actions.noactions') }}</div>
      </div>
    </template>
  </div>
</template>

<script>
import { BillMgrTool } from '@/models/BillMgr/Tools';
import serialize from '@/utils/serialize';
import { PrivateTariff } from '@/models/BillMgr/PrivateTariff';
import showErrorModal from '@/mixins/showErrorModal';
export default {
  name: 'PrivateContextMenu',
  mixins: [showErrorModal],
  props: {
    tariff: {
      type: Object,
      required: true,
      validator: obj => obj instanceof PrivateTariff,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      menuOther: [],
    };
  },
  computed: {
    tools() {
      return this.$store.state.modulePrivate.tools;
    },
    menu() {
      return [[...this.menuOther]];
    },
  },
  methods: {
    onClick(item) {
      if (item.disabled) return;
      if (item.handler && item.handler === 'func') this.askSure(item.key);
      else if (item.handler && typeof item.handler === 'function') item.handler(item);
      else if (item.tool && item.tool instanceof BillMgrTool) {
        try {
          this.handleBillMgrTool(item);
        } catch (e) {
          console.error(e);
        }
      } else if (!item.attrs) alert(`id: ${this.tariff.id}, \r\naction: ${item.title}`);
    },
    handleBillMgrTool(item) {
      if (item.tool.type === 'window') this.goToWindow(item);
      else throw new Error(`Handler for tool type ${item.tool.type} is not isset`);
    },
    goToWindow(item) {
      this.$store
        .dispatch('modulePrivate/fetchBillMgrToolAction', {
          func: item.tool.func,
          id: this.tariff.id,
        })
        .then(data => {
          if (data && data.ok && data.ok.type === 'url' && data.ok.v) {
            let payload = item.payload ? serialize(item.payload) : '';
            window.open(data.ok.v + payload);
          }
        })
        .catch(e => {
          this.showError(e);
        });
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "actions": {
      "change": "изменить",
      "stop": "остановить",
      "panel": "перейти в панель",
      "history": "история",
      "serverPanel":"Панель управления сервером",

      "start": "включить",
      "process": "в процессе",
      "restart": "перезагрузить",
      "plesk": "Панель Plesk",
      "console": "открыть консоль",
      "createBackup": "создать бэкап",
      "recreateServer": "пересоздать сервер",
      "removeHosting": "удалить хостинг",
      "removeOrder": "удалить заказ",
      "noactions": "Нет доступных действий."
    },
    "modal": {
      "sure": {
        "text": "Вы собираетесь %{msg} сервер. Вы уверены?",
        "confirm": "Да, уверен",
        "close": "Закрыть",
        "success": "Запрос выполняется. Пожалуйста, дождитесь изменения статуса",
        "fail": "Кажется, что-то пошло не так. Пожалуйста, попробуйте позже."
      },
      "removeFromBasket": {
        "success": "Заказ успешно удалён"
      }
    }
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';

$vcm-colors = {
  primary: $primary-color,
  success: $success-color,
  del: $color-red.light,
}
$vcm-colors-hover = {
  primary: $color-blue.medium,
  success: darken($success-color, 20%),
  del: darken($color-red.light, 20%),
}

.private-context-menu {

  &__group {
    & + & {
      margin-top: 2.5rem;

      +breakpoint(sm-and-up) {
        margin-top: (1.25rem * 3);
      }
    }
  }
  &__item {
    display: block;
    color: var(--text);
    text-decoration: none;
    cursor: pointer;
    transition: color 0.3s ease;

    &:first-letter {
      text-transform: uppercase;
    }

    &:focus {
      outline: none;
    }
    &:hover {
      color: $primary-color;
    }

    &[disabled] {
      pointer-events: none;
      cursor: default;
      opacity: 0.6;
    }

    & + & {
      margin-top: 1.25rem;
    }

    for $key, $value in $vcm-colors {
      &--{$key} {
        color: $value;
      }
    }
    for $key, $value in $vcm-colors-hover {
      &--{$key}:hover {
        color: $value;
      }
    }

  }
}
</style>
<style lang="stylus">
@require '~@/assets/styles/vars/variables';

.private-context-menu {
  .action-popup & {
    padding: 1.5rem 1rem;
    width: calc(100vw - 0.75rem);

    +breakpoint(sm-and-up) {
      padding: 1.5rem;
      width: 260px;
    }
  }
}
</style>
